import React, { Component,  Suspense, lazy } from 'react';

// import Landing from './containers/Landing';
// import Home from './containers/Home';
// import Spin from './containers/Spin';
// import Login from './containers/Auth/Login';
// import Register from './containers/Auth/Register';
// import ForgotPassword from './containers/Auth/ForgotPassword';
// import ResetPassword from './containers/Auth/ForgotPassword/ResetPassword';
// import RegisterOtp from './containers/Auth/Register/Otp';
// import Profile from "./containers/Profile";
// import EditProfile from "./containers/Profile/EditProfile";
// import ChangePassword from "./containers/Profile/ChangePassword";
// import ParticipatingOutlets from './containers/Profile/ParticipatingOutlets';
// import Vouchers from "./containers/Vouchers";
// import VoucherDetails from "./containers/Vouchers/VoucherDetails";
// import RedeemVoucher from "./containers/Vouchers/RedeemVoucher";
// import SpinResults from "./containers/SpinResults";
// import SpinResultDetails from "./containers/SpinResults/SpinResultDetails";
// import Logout from './containers/Auth/Logout';
// import ForgotOtp from "./containers/Auth/ForgotPassword/ForgotOtp";
// import ContactUs from "./containers/Profile/ContactUs";
import Loader from './containers/Loader';
// import MenuDrawer from './containers/MenuDrawer';
// import IncompatibleView from './containers/IncompatibleView';
// import TermsAndCondition from './containers/Profile/TermsAndCondition';
// import PrivacyAndPolicy from './containers/Profile/PrivacyAndPolicy';
// import QrContainer from './containers/QRCode';
// import SelectDate from './containers/Vouchers/SelectDate'
// import MultipleSpin from './containers/MultipleSpin';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect, Route, Switch } from 'react-router-dom';

const Landing = lazy(() => import('./containers/Landing'));
const Home = lazy(() => import('./containers/Home'));
const Spin = lazy(() => import('./containers/Spin'));
const Login = lazy(() => import('./containers/Auth/Login' ));
const Register = lazy(() => import('./containers/Auth/Register'));
const ForgotPassword = lazy(() => import('./containers/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('./containers/Auth/ForgotPassword/ResetPassword'));
const RegisterOtp = lazy(() => import('./containers/Auth/Register/Otp'));
const Profile = lazy(() => import("./containers/Profile"));
const EditProfile = lazy(() => import("./containers/Profile/EditProfile"));
const ChangePassword = lazy(() => import("./containers/Profile/ChangePassword"));
const ParticipatingOutlets = lazy(() => import('./containers/Profile/ParticipatingOutlets'));
const Vouchers = lazy(() => import("./containers/Vouchers"));
const VoucherDetails = lazy(() => import("./containers/Vouchers/VoucherDetails"));
const RedeemVoucher = lazy(() => import("./containers/Vouchers/RedeemVoucher"));
const SpinResults = lazy(() => import("./containers/SpinResults"));
const SpinResultDetails = lazy(() => import("./containers/SpinResults/SpinResultDetails"));
const Logout = lazy(() => import('./containers/Auth/Logout'));
const ForgotOtp = lazy(() => import("./containers/Auth/ForgotPassword/ForgotOtp"));
const ContactUs = lazy(() => import("./containers/Profile/ContactUs"));
// const Loader = lazy(() => import('./containers/Loader'));
const MenuDrawer = lazy(() => import('./containers/MenuDrawer'));
const IncompatibleView = lazy(() => import('./containers/IncompatibleView'));
// const TermsAndCondition = lazy(() => import('./containers/Profile/TermsAndCondition'));
// const PrivacyAndPolicy = lazy(() => import('./containers/Profile/PrivacyAndPolicy'));
const QrContainer = lazy(() => import('./containers/QRCode'));
const SelectDate = lazy(() => import('./containers/Vouchers/SelectDate'));
const MultipleSpin = lazy(() => import('./containers/MultipleSpin'));



class App extends Component {

  closeButton = ({ closeToast }) => (
    <div
    style={{marginTop:"15px",marginRight:"5px"}}
      onClick={closeToast}
    >
    X
    </div>
  );

  render () {
    let routes = (

      <Switch>
        <Route path="/login" component={Login} /> 
        <Route path="/loader" component={Loader} />
        <Route path="/menu" component={MenuDrawer} /> 
        <Route path="/register" component={Register} />
        <Route path="/scan-qrcode" component={QrContainer} />
        {/* <Route path="/terms-and-conditions" component={TermsAndCondition} /> 
        <Route path="/privacy-and-policy" component={PrivacyAndPolicy} />  */}
        <Route path="/verify-register-otp" component={RegisterOtp} />  
        <Route path="/verify-forgot-otp" component={ForgotOtp} />  
        <Route path="/forgot-password" component={ForgotPassword} /> 
        <Route path="/reset-password" component={ResetPassword} /> 
        <Route path="/spin" component={Spin} /> 
        <Route exact path="/" component={Landing} /> 
        <Redirect to="/login" />
      </Switch>
    );
    let isAuth = JSON.parse(localStorage.getItem("userToken"));
    if(isAuth) {
      routes = (
          <Switch>
            <Route path="/loader" component={Loader} />
            <Route path="/home" component={Home} />
            <Route path="/logout" component={Logout} />
            <Route path="/spin" component={Spin} /> 
            <Route path="/multiple-spin" component={MultipleSpin} />  
            <Route path="/menu" component={MenuDrawer} /> 
            <Route path="/scan-qrcode" component={QrContainer} />
            {/* <Route path="/terms-and-conditions" component={TermsAndCondition} />  */}
            {/* <Route path="/privacy-and-policy" component={PrivacyAndPolicy} />  */}
            <Route path="/profile" component={Profile} /> 
            <Route path="/edit-profile" component={EditProfile} />
            <Route path="/change-password" component={ChangePassword} /> 
            <Route path="/contact-us" component={ContactUs} /> 
            <Route path="/participating-outlets" component={ParticipatingOutlets} />
            <Route path="/vouchers" component={Vouchers} /> 
            <Route exact path="/voucher-details/:id" component={VoucherDetails} /> 
            <Route path="/redeem-voucher" component={RedeemVoucher} /> 
            <Route path="/voucher-details/:id/select-date" component={SelectDate} /> 
            <Route path="/spin-results" component={SpinResults} /> 
            <Route path="/spinresult-details/:id" component={SpinResultDetails} />  
            <Route exact path="/" component={Landing} /> 
            <Redirect to="/home" />
          </Switch>
      );
    }
    if(window.innerWidth >= 1024){
      routes = (
        <Switch>
          <Route path="/incompatible-view" component={IncompatibleView} />
          <Route exact path="/" component={Landing} /> 
          <Redirect to="/" />
        </Switch>
      )
    }
    return (
        <div className="App">
          <Suspense fallback={<></>}>
          {routes}
          </Suspense>
          <ToastContainer closeButton={this.closeButton} />
        </div>
    );
  }
}

export default App;
