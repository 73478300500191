import * as actionTypes from './actionTypes'

const initialState = {
    loading : false,
    success : false,
    redeemVoucher : [],
    error : '',

}

const reducer = (state = initialState , action ) => {

    switch(action.type){
        case actionTypes.REDEEM_VOUCHER :
            return { ...state , loading : true , success : false }
        case actionTypes.REDEEM_VOUCHER_SUCCESS: 
            return { ...state , loading : false , success : true , redeemVoucher : action.payload } 
        case actionTypes.REDEEM_VOUCHER_FAILED :
            return { ...state , loading : false , success : false , error : action.payload }
            
               
        default :
            return state
    }
}  

export default reducer;