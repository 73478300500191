import * as actionTypes from './actionType';

const initialState = {
    loading : false ,
    sendOtpSuccess : false ,
    otpId : '',
    checkOtpSuccess : false ,
    updateNewPassword : false ,
    error : '',
    memberId:""
}

const reducer = ( state = initialState , actions) => {

    switch(actions.type){
        case actionTypes.RESET_OTP :
            return {...state, otpId:"",sendOtpSuccess:false,checkOtpSuccess:false,memberId:"",updateNewPassword:false}
        case actionTypes.FORGOT_PASSWORD_SEND_OTP_START :
            return { ...state , loading : true}
        case actionTypes.FORGOT_PASSWORD_SEND_OTP_SUCCESS :
            return { ...state , loading : false , sendOtpSuccess : true ,checkOtpSuccess : false ,updateNewPassword : false , otpId : actions.payload }
        case actionTypes.FORGOT_PASSWORD_SEND_OTP_FAIL :
            return { ...state , loading : false , sendOtpSuccess : false ,checkOtpSuccess : false ,updateNewPassword : false , error : actions.payload }

        case actionTypes.FORGOT_PASSWORD_CHECK_OTP_START:
            return { ...state , loading : true}
        case actionTypes.FORGOT_PASSWORD_CHECK_OTP_SUCCESS :
            return { ...state , loading : false , checkOtpSuccess : true,sendOtpSuccess : false ,updateNewPassword : false ,memberId : actions.payload }
        case actionTypes.FORGOT_PASSWORD_CHECK_OTP_FAIL :
            return { ...state , loading : false , checkOtpSuccess : false ,sendOtpSuccess : false ,updateNewPassword : false , error : actions.payload  }

        case actionTypes.UPDATE_PASSWORD_START:
            return { ...state , loading : true}
        case actionTypes.UPDATE_PASSWORD_SUCCESS :
            return { ...state , loading : false , updateNewPassword : true, checkOtpSuccess : false ,sendOtpSuccess : false   }
        case actionTypes.UPDATE_PASSWORD_FAIL :
            return { ...state , loading : false , updateNewPassword : false ,checkOtpSuccess : false ,sendOtpSuccess : false, error : actions.payload  }

        default:
            return state;
    }
}

export default reducer;