import {


    REGISTER_SEND_OTP_STARTED,
    REGISTER_SEND_OTP_SUCCESS,
    REGISTER_SEND_OTP_FAILED,
    REGISTER_VERIFY_OTP_STARTED,
    REGISTER_VERIFY_OTP_SUCCESS,
    REGISTER_VERIFY_OTP_FAILED,
    RESET_OTP

} from "./actionTypes";

const initialState = {
    reg_otp: "",
    loading: false,
    otpSuccess: false,
    error: "",
    regSuccess: false



};

export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_OTP :
            return {...state, reg_otp:"", regSuccess: false ,success:false}
        case REGISTER_SEND_OTP_STARTED:
            return { ...state, loading: true , otpSuccess : false};
        case REGISTER_SEND_OTP_SUCCESS:
            return {
                ...state, loading: false,
                reg_otp: action.payload,
                otpSuccess: true,
                error: "",
            };
        case REGISTER_SEND_OTP_FAILED:
            return {
                ...state, loading: false,
                otpSuccess: false,
                reg_otp: "",
                error: action.payload,
            };

        case REGISTER_VERIFY_OTP_STARTED:
            return { ...state, loading: true , regSuccess : false };
        case REGISTER_VERIFY_OTP_SUCCESS:
            return {
                ...state, loading: false,
                regSuccess: true,
                reg_otp:"",
                error: "",
            };
        case REGISTER_VERIFY_OTP_FAILED:
            return {
                ...state, loading: false,
                success: false,
                regSuccess: false,
                // reg_otp:"",
                error: action.payload,
            };

        default:
            return state;
    }
}
