import * as actionTypes from "./actionTypes";

const initialState = {
    loading: false,
    success: false,
    error : ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.SCAN_QR_CODE :
            return {...state, loading: true , success : false}
        case actionTypes.SCAN_QR_CODE_SUCCESS:
            return { ...state, loading: false , success : true};
        case actionTypes.SCAN_QR_CODE_FAILED:
            return { ...state, loading: false , error : action.payload };
        default:
            return state;
    }
}
