import * as actionTypes from "./actionTypes";

const initialState = {
    clientAuth: {},
    loading:false,
    success:false,
    settings:{},
    settingSuccess: false,
    error:"",
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GENERATE_ACCESS_TOKEN_STARTED:
            return { ...state, loading: true  };
        case actionTypes.GENERATE_ACCESS_TOKEN_SUCCESS:
            return { ...state, loading: false , success: true , clientAuth : action.payload};
        case actionTypes.GENERATE_ACCESS_TOKEN_FAILED:
            return { ...state,  loading: false , error : action.payload}; 
        case actionTypes.GET_ALL_SETTINGS_STARTED:
            return { ...state,  loading: true }; 
        case actionTypes.GET_ALL_SETTINGS_SUCCESS:
            return { ...state,  loading: false , settingSuccess : true , settings : action.payload}; 
        case actionTypes.GET_ALL_SETTINGS_FAIL:
            return { ...state,  loading: false , settingSuccess : false  , error : action.payload};  
        default:
            return state;
    }
}

export default reducer;