import {
    
    GRAND_DRAW_FAILED, GRAND_DRAW_STARTED, GRAND_DRAW_SUCCESS
    
} from "./actionTypes";

const initialState = {
    data: [],
    loading:false,
    success:false,
    error:"",

};


export default function(state = initialState, action) {
    switch (action.type) {
        case GRAND_DRAW_STARTED:
            return { ...state, loading: true  };
        case GRAND_DRAW_SUCCESS:
            return { ...state, loading: false,
                data: action.payload,
                success: true,
                error: "", };
        case GRAND_DRAW_FAILED:
            return { ...state,  loading: false,
                data: [],
                success: false,
                error: action.payload, };
        default:
            return state;
    }
}
