export const FORGOT_PASSWORD_SEND_OTP_START = 'FORGOT_PASSWORD_START'
export const FORGOT_PASSWORD_SEND_OTP_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_SEND_OTP_FAIL = 'FORGOT_PASSWORD_FAIL'

export const FORGOT_PASSWORD_CHECK_OTP_START = 'FORGOT_PASSWORD_CHECK_OTP_START'
export const FORGOT_PASSWORD_CHECK_OTP_SUCCESS = 'FORGOT_PASSWORD_CHECK_OTP_SUCCESS'
export const FORGOT_PASSWORD_CHECK_OTP_FAIL = 'FORGOT_PASSWORD_CHECK_OTP_FAIL'

export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'

export const RESET_OTP = "RESET_OTP";