import {
    LOGIN_STARTED,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOG_OUT,
    LOGOUT_STARTED,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL


} from "./actionTypes";

const initialState = {
    user_auth: [],
    loading: false,
    success: false,
    logoutSuccess : false,
    error: "",

};


export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_STARTED:
            return { ...state, loading: true };
        case LOGIN_SUCCESS:
            return {
                ...state, loading: false,
                user_auth: action.payload,
                success: true,
                error: "",
            };
        case LOGIN_FAILED:
            return {
                ...state, loading: false,
                user_auth: [],
                success: false,
                error: action.payload,
            };

        case LOGOUT_STARTED:
            return { ...state, loading: true };
        case LOGOUT_SUCCESS:
            return {
                ...state, 
                loading: false,
                logoutSuccess: true,
                error: "",
            };
        case LOGOUT_FAIL:
            return {
                ...state, 
                loading: false,
                logoutSuccess: false,
                error: action.payload,
            };
        case LOG_OUT:
            return {
                user_auth: [],
                loading: false,
                success: false,
                error: ""
            };

        default:
            return state;
    }
}
