export const GET_PROFILE_STARTED = "GET_PROFILE_STARTED";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILED = "GET_PROFILE_FAILED";

export const GET_PARTICIPATING_OUTLETS_STARTED = "GET_PARTICIPATING_OUTLETS_STARTED";
export const GET_PARTICIPATING_OUTLETS_SUCCESS = "GET_PARTICIPATING_OUTLETS_SUCCESS";
export const GET_PARTICIPATING_OUTLETS_FAILED = "GET_PARTICIPATING_OUTLETS_FAILED";

export const UPDATE_PROFILE_STARTED = "UPDATE_PROFILE_STARTED";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";

export const CHANGE_PASSWORD_STARTED = "CHANGE_PASSWORD_STARTED";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const CONTACT_INQUIRY_STARTED = "CONTACT_INQUIRY_STARTED";
export const CONTACT_INQUIRY_SUCCESS = "CONTACT_INQUIRY_SUCCESS";
export const CONTACT_INQUIRY_FAILED = "CONTACT_INQUIRY_FAILED";

export const GET_CMS_STARTED = "GET_CMS_STARTED";
export const GET_CMS_SUCCESS = "GET_CMS_SUCCESS";
export const GET_CMS_FAIL = "GET_CMS_FAIL";

export const CLEAR_CMS_DATA = "CLEAR_CMS_DATA";