import React , { Component } from 'react';
import './Loader.css';
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
const override = css`
height: 100%;
position: absolute;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
z-index: 9;
background-color: #f2f2f28c;
`;
class Loader extends Component {
    
    render() {
        return (
            // <div className='loader'>
            // </div>
            <div className="loading">
            <BeatLoader textAlign="center" css={override} size={15} margin={2} color={"#da0000"} loading={this.props.loading} />
          </div>
        );
    }
}

export default Loader;