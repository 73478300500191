import TokenReducer from './Tokens/reducer'
import loginReducer from './login/reducer'
import registerReducer from './register/reducer'
import profileReducer from './profile/reducer'
import forgotPassword from './forgotPassword/reducer';
import homeReducer from "./home/reducer";
import wheelReducer from './spin/reducer'
import incompatibleViewReducer from './incompatibleView/reducer'
import vouchersReducer from './vouchers/reducer'
import scanQRCode from './scanqrcode/reducer'
import redeemVoucher from './redeemVouchers/reducer'
import grandDraw from './grandDraw/reducer'
import spinResultList from './spinresultlist/reducer'
import spinResultDetails from './spinresultdetails/reducer'
import { combineReducers } from "redux"; 


const rootReducer = combineReducers({
    clientToken : TokenReducer,
    user_auth: loginReducer,
    register: registerReducer,
    profile: profileReducer,
    forgotPassword : forgotPassword,
    home:homeReducer,
    wheel : wheelReducer,
    incompatibleView : incompatibleViewReducer,
    vouchers : vouchersReducer,
    scanqrcode : scanQRCode,
    redeemVoucher : redeemVoucher,
    grandDraw : grandDraw,
    spinResultList : spinResultList,
    spinResultDetails : spinResultDetails
})

export default rootReducer;