import {
    GET_MULTIPLE_BLOCKS_STARTED,
    GET_MULTIPLE_BLOCKS_SUCCESS,
    GET_MULTIPLE_BLOCKS_FAILED,
    GET_BANNER_STARTED,
    GET_BANNER_SUCCESS,
    GET_BANNER_FAILED

} from "./actionTypes";

const initialState = {
    // multipleBlocks: [],
    multipleBlocks : {
        loading: false,
        success: false,
        multipleBlocks: [],
        error:''
    },
    banner : {
        loading: false,
        success: false,
        banner: [],
        error:''
    },
    // banner : [],
    // loading: false,
    // blockSuccess: false,
    // bannerSuccess: false,
    // error: "",

};


export default function (state = initialState, action) {
    switch (action.type) {
        case GET_MULTIPLE_BLOCKS_STARTED:
            return { ...state, multipleBlocks : {...state, loading:true}};
        case GET_MULTIPLE_BLOCKS_SUCCESS:
            return {
                ...state,
                multipleBlocks : {loading:false,success:true, multipleBlocks: action.payload, error:""}
            };
        case GET_MULTIPLE_BLOCKS_FAILED:
            return {
                ...state,
                multipleBlocks : {loading:false,success:false, multipleBlocks:[], error: action.payload}
            };

        case GET_BANNER_STARTED:
            return { ...state, banner : {...state, loading:true} };
        case GET_BANNER_SUCCESS:
            return {
                ...state, banner : { loading:false,success:true, banner: action.payload, error:""}
            };
        case GET_BANNER_FAILED:
            return {
                ...state,  banner : { loading:false,success:false, banner:[], error: action.payload}
            };

        default:
            return state;
    }
}
