import * as actionTypes from './actionTypes'
const initialState = {
    loading : false,
    success : false,
    data : {},
    error : '',
    spinResult : {
        loading: false,
        success: false,
        data: [],
        error:''
    }

}

const reducer = (state = initialState , action ) => {

    switch(action.type){
        case actionTypes.GET_WHEEL_DATA :
            return { ...state , loading : true , success : false }
        case actionTypes.GET_WHEEL_DATA_SUCCESS: 
            return { ...state , loading : false , success : true , data : action.payload } 
        case actionTypes.GET_WHEEL_DATA_FAIL :
            return { ...state , loading : false , success : false , error : action.payload }

        case actionTypes.SPIN_WHEEL_RESULT :
            return { ...state, spinResult : {...state.spinResult,  loading : true , success : false} }
        case actionTypes.SPIN_WHEEL_RESULT_SUCCESS: 
            return { ...state, spinResult : {...state.spinResult,  loading : false , success : true, data: action.payload}  } 
        case actionTypes.SPIN_WHEEL_RESULT_FAIL :
            return { ...state, spinResult : {...state.spinResult,  loading : false , success : false, error: action.payload} }        
        default :
            return state
    }
}  

export default reducer;