import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import * as serviceWorker from "./serviceWorker";

import thunk from 'redux-thunk';
import { Provider } from 'react-redux'; 
import rootReducer from "./services"
import { createStore , applyMiddleware , compose } from 'redux';
import { BrowserRouter } from 'react-router-dom';
import Interceptor from './hoc/interceptor';
// const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  : null || compose;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
Interceptor.interceptor();
const store = createStore(rootReducer , composeEnhancers(
  applyMiddleware(thunk)
));

  const app = (
    <Provider store={store}>
      <BrowserRouter>
        {/* <React.StrictMode> */}
          <App />
        {/* </React.StrictMode> */}
      </BrowserRouter>
    </Provider>
  );
ReactDOM.render(app, document.getElementById('root'));
// registerServiceWorker();
serviceWorker.register();

