import * as actionTypes from './actionTypes'

const initialState = {
    loading : false,
    success : false,
    data : [],
    error : '',
}

const reducer = (state = initialState , action ) => {

    switch(action.type){
        case actionTypes.GET_SPIN_RESULT_DETAILS :
            return { ...state , loading : true , success : false }
        case actionTypes.GET_SPIN_RESULT_DETAILS_SUCCESS: 
            return { ...state , loading : false , success : true , data : action.payload } 
        case actionTypes.GET_SPIN_RESULT_DETAILS_FAILED :
            return { ...state , loading : false , success : false , error : action.payload }
                           
        default :
            return state
    }
}  

export default reducer;