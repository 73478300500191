import * as actionTypes from "./actionTypes";

const initialState = {
    profileInfo: [],
    participatingOutlets: [],
    loading: false,
    profileSuccess: false,
    outletSuccess: false,
    editSuccess: false,
    passwordSuccess: false,
    inquirySuccess: false,
    cms: {},
    cmsSuccess :false,
    error: "",

};


export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_PROFILE_STARTED:
            return { ...state, loading: true };
        case actionTypes.GET_PROFILE_SUCCESS:
            return {
                ...state, loading: false,
                profileInfo: action.payload,
                profileSuccess: true,
                editSuccess: false,
                outletSuccess: false,
                passwordSuccess: false,
                inquirySuccess: false,
                error: "",
            };
        case actionTypes.GET_PROFILE_FAILED:
            return {
                ...state, loading: false,
                profileInfo: [],
                profileSuccess: false,
                editSuccess: false,
                outletSuccess: false,
                passwordSuccess: false,
                inquirySuccess: false,
                error: action.payload,
            };

        case actionTypes.GET_PARTICIPATING_OUTLETS_STARTED:
            return { ...state, loading: true };
        case actionTypes.GET_PARTICIPATING_OUTLETS_SUCCESS:
            return {
                ...state, loading: false,
                participatingOutlets: action.payload,
                outletSuccess: true,
                profileSuccess: false,
                editSuccess: false,
                passwordSuccess: false,
                inquirySuccess: false,
                error: "",
            };
        case actionTypes.GET_PARTICIPATING_OUTLETS_FAILED:
            return {
                ...state, loading: false,
                participatingOutlets: [],
                outletSuccess: false,
                profileSuccess: false,
                editSuccess: false,
                passwordSuccess: false,
                inquirySuccess: false,
                error: action.payload,
            };

        case actionTypes.UPDATE_PROFILE_STARTED:
            return { ...state, loading: true };
        case actionTypes.UPDATE_PROFILE_SUCCESS:
            return {
                ...state, loading: false,
                editSuccess: true,
                outletSuccess: false,
                profileSuccess: false,
                passwordSuccess: false,
                inquirySuccess: false,
                error: "",
            };
        case actionTypes.UPDATE_PROFILE_FAILED:
            return {
                ...state, loading: false,
                editSuccess: false,
                outletSuccess: false,
                profileSuccess: false,
                passwordSuccess: false,
                inquirySuccess: false,
                error: action.payload,
            };

        case actionTypes.CHANGE_PASSWORD_STARTED:
            return { ...state, loading: true };
        case actionTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state, loading: false,
                passwordSuccess: true,
                outletSuccess: false,
                profileSuccess: false,
                editSuccess: false,
                inquirySuccess: false,
                error: "",
            };
        case actionTypes.CHANGE_PASSWORD_FAILED:
            return {
                ...state, loading: false,
                editSuccess: false,
                outletSuccess: false,
                profileSuccess: false,
                passwordSuccess: false,
                inquirySuccess: false,
                error: action.payload,
            };

        case actionTypes.CONTACT_INQUIRY_STARTED:
            return { ...state, loading: true };
        case actionTypes.CONTACT_INQUIRY_SUCCESS:
            return {
                ...state, loading: false,
                passwordSuccess: true,
                outletSuccess: false,
                profileSuccess: false,
                editSuccess: false,
                inquirySuccess: true,
                error: "",
            };
        case actionTypes.CONTACT_INQUIRY_FAILED:
            return {
                ...state, loading: false,
                editSuccess: false,
                outletSuccess: false,
                profileSuccess: false,
                passwordSuccess: false,
                inquirySuccess: false,
                error: action.payload,
            };
        case actionTypes.GET_CMS_STARTED:
            return {
                ...state, loading: true,
                editSuccess: false,
                outletSuccess: false,
                profileSuccess: false,
                passwordSuccess: false,
                inquirySuccess: false,
                cmsSuccess: false,
            };
        case actionTypes.GET_CMS_SUCCESS:
            return {
                ...state, loading: false,
                editSuccess: false,
                outletSuccess: false,
                profileSuccess: false,
                passwordSuccess: false,
                inquirySuccess: false,
                cmsSuccess: true,
                cms : action.payload.data
            };
        case actionTypes.GET_CMS_FAIL:
            return {
                ...state, loading: false,
                editSuccess: false,
                outletSuccess: false,
                profileSuccess: false,
                passwordSuccess: false,
                inquirySuccess: false,
                cmsSuccess :false,
                error: action.payload,
            };

        case actionTypes.CLEAR_CMS_DATA : 
            return{
                ...state, 
                cms:{}
            }
        default:
            return state;
    }
}
