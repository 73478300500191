import * as actionTypes from './actionTypes'

const initialState = {
    loading : false,
    success : false,
    vouchers : [],
    error : '',
    voucherDetails : {
        loading: false,
        success: false,
        voucherDetail : [],
        error : ''
    }

    

}

const reducer = (state = initialState , action ) => {

    switch(action.type){
        case actionTypes.GET_MY_VOUCHERS :
            return { ...state , loading : true , success : false }
        case actionTypes.GET_MY_VOUCHERS_SUCCESS: 
            return { ...state , loading : false , success : true , vouchers : action.payload } 
        case actionTypes.GET_MY_VOUCHERS_FAILED :
            return { ...state , loading : false , success : false , error : action.payload }
            
        case actionTypes.GET_VOUCHER_DETAIL :
            return { ...state, voucherDetails : {...state.voucherDetails,  loading : true , success : false} }
        case actionTypes.GET_VOUCHER_DETAIL_SUCCESS: 
            return { ...state, voucherDetails : {...state.voucherDetails,  loading : false , success : true, voucherDetail: action.payload}  } 
        case actionTypes.GET_VOUCHER_DETAIL_FAILED :
            return { ...state, voucherDetails : {...state.voucherDetails,  loading : false , success : false, error: action.payload} }        
               
        default :
            return state
    }
}  

export default reducer;